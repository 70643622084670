import React from "react";


import easyIcon1 from "../../../../static/images/easy-icon1.png";
import easyIcon2 from "../../../../static/images/easy-icon2.png";
import easyIcon3 from "../../../../static/images/easy-icon3.png";

const EasySteps = (props) => {
    const { title, subtitle, paragraph } = props;
    return (
        <section className="internet-section2">
            <div className="container mx-auto px-4">
                <h2 className="h2">{title}</h2>
                <p className="p2 text-center">{subtitle}</p>
                <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="relative">
                        <div className="gray-round">
                            <img src={easyIcon1} alt="internet" className="internet-sec2-icon" />
                        </div>
                        <h4 className="h4">Signup online</h4>
                    </div>
                    <div className="relative">
                        <div className="gray-round">
                            <img src={easyIcon2} alt="internet" className="internet-sec2-icon" />
                        </div>
                        <h4 className="h4">We'll deliver your gear</h4>
                    </div>
                    <div className="relative">
                        <div className="gray-round">
                            <img src={easyIcon3} alt="internet" className="internet-sec2-icon" />
                        </div>
                        <h4 className="h4">Plug it in</h4>
                    </div>
                </div>
                <p className="p2 text-center mt-8">{paragraph}</p>
            </div>
        </section>
    )
}

export default EasySteps;